import React from "react";
import { graphql, HeadFC } from "gatsby";
import Layout from "../components/layout";
import { Trans } from "react-i18next";
import { HeaderSpacer } from "../components/header";
import Heading from "../components/heading";
import Timetable from "../components/timetable";
import { PromoWebshop } from "../components/promo";
import SEO from "../seo";

const Ask = ({
  question,
  children: answer,
}: {
  question: string;
  children: React.ReactNode;
}) => (
  <div className="p-4 my-2">
    <h4 className="text-2xl text-green-500 leading-relaxed mb-2">
      <Trans>{question}</Trans>
    </h4>
    <p>{typeof answer === "string" ? <Trans>{answer}</Trans> : answer}</p>
  </div>
);

const FAQ = () => {
  return (
    <Layout className="snap-proximity scroll-pt-32">
      <div className="max-w-screen-lg mx-auto">
        <HeaderSpacer />
        <Heading>Customer's Frequently Asked Questions</Heading>
        <Ask question="What are your opening hours?">
          <Timetable className="mx-auto lg:mx-4" />
        </Ask>

        <Ask question="What are Panzerò’s delivery hours?">
          {/* prettier-ignore */}
          <Trans>When you order directly through our website www.panzero.nl our delivery hours are the following:</Trans>
          <ol>
            <li>
              <Trans>Tuesday</Trans> - <Trans>Thursday</Trans>: 17:00 - 21:30
            </li>
            <li>
              <Trans>Friday</Trans> - <Trans>Saturday</Trans>: 17:00 - 22:00
            </li>
            <li>
              <Trans>Sunday</Trans>: 14:00 - 21:00
            </li>
          </ol>
          {/* prettier-ignore */}
          <Trans>When you want to get your Panzerò fix any time day or night, please use our delivery exclusive partner</Trans>
          <a
            className="bg-yellow-500 px-1"
            href="https://www.ubereats.com/nl-en/store/panzero-italian-streat-food-from-puglia/MnCo-EnhSd2o8KuhIZYjzw"
            target="_blank"
          >
            UberEats
          </a>
          !
        </Ask>

        {/* prettier-ignore */}
        <Ask question="How do I make a reservation?">If you’d like to guarantee a spot and no wait time, we do recommend making a reservation. You can easily book a table on our website by clicking on “BOOK A TABLE”. You’ll receive a confirmation email immediately after booking. For groups of 11 or more, once you make your reservation through our website we will contact you personally by phone or email to best accommodate your request.</Ask>
        {/* prettier-ignore */}
        <Ask question="How do I get a loyalty card?">When you’re finished dining with us and you pay your bill at the Kassa the server will offer you our loyalty card. If you are not dining in our restaurant we will include a loyalty card with every online order.</Ask>
        {/* prettier-ignore */}
        <Ask question="How does your loyalty program work?">Once you have your Panzerò loyalty card, bring it with you each time you visit us. You’ll get one stamp for every 10€ you spend. Collect 10 stamps for one free small panzerotto or 20 stamps for one free large panzerotto (Classico, Prosciutto, Salame, Stufato).</Ask>
        {/* prettier-ignore */}
        <Ask question="I have an old loyalty card, can I still use it?">Yes! Just ask us for the new version and we will convert your old card and any stamps you previously had to the new card.</Ask>
        {/* prettier-ignore */}
        <Ask question="I have some food allergies and/or intolerances. What can I eat?">Please visit our Allergens page on our website for a full list of all the possible allergens contained in our dishes.</Ask>
        {/* prettier-ignore */}
        <Ask question="I’m pregnant, can I eat your cheese?">Yes! All of our dairy products including our Stracciatella cheese go through a pasteurization process and therefore are safe for everyone to enjoy.</Ask>
        {/* prettier-ignore */}
        <Ask question="Where are you located?">We are located on Botersloot 50a – 3011HH – Rotterdam, only 350 meters away from Blaak station!</Ask>
        {/* prettier-ignore */}
        <Ask question="Is there parking near Panzerò?">There are several spots near our restaurant on Botersloot that offer parking. Other great options include parking near Loetje Rotterdam for a quick walk over to Panzerò, or the parking garage inside Markthal, which is only 200 meters away from Panzerò!</Ask>
        {/* prettier-ignore */}
        <Ask question="Italian Spoken Language">The majority of our staff is Italian, therefore, Italian is the most common language, followed by English spoken in our restaurant. However, we have menus printed in English and Dutch, so please ask your server for the menu version you prefer.</Ask>
        {/* prettier-ignore */}
        <Ask question="Is the dough vegan?">Absolutely! Our dough is 100% vegan. It contains the highest-quality extra virgin olive oil, flour, salt, water, and other secret ingredients!</Ask>
        {/* prettier-ignore */}
        <Ask question="Why is the tuna pink? It looks like ham to me, how can I be sure?">Our tuna is smoked and cured which gives it that bright pink color similar to ham.</Ask>
        {/* prettier-ignore */}
        <Ask question="What are panzerotti?">Panzerotti are considered Puglia's most beloved street food and one of our specialties! These pockets of fried dough perfection come filled with a plethora of various savory and sweet fillings. Ours are handcrafted with the finest Italian ingredients imported directly from Puglia right to your table! If you are visiting us during lunch, a small panzerotto is the perfect choice for a quick bite. If you are really hungry we suggest our large panzerotto! Having a sampler of our small panzerotti is also a great way to share and experience all of our different panzerotti varieties.</Ask>
        {/* prettier-ignore */}
        <Ask question="What is the difference between panzerotto and pizza?">The difference is in the dough. Our dough is a mixture of different flours, a mother yeast, a slow leavening time, and a lot of patience. Panzerotto resembles a calzone, so we like to call it a folded pizza. We serve all of our panzerotto fried, but let us know if you prefer it baked!</Ask>
        {/* prettier-ignore */}
        <Ask question="What kind of oil do you use to fry your panzerotti?">We prefer to fry with high oleic sunflower oil because it stands up well to the high heat during frying. It’s also an overall healthier oil to cook with, and it has a very mild light flavour. This allows the delicious panzerotti and all its seasonings to shine through.</Ask>
        {/* prettier-ignore */}
        <Ask question="What is a Puccia?">Puccia is our artisanal homemade flatbread originating from the area of Salento in Puglia, Italy.</Ask>
        {/* prettier-ignore */}
        <Ask question="Do you sell any of your products or ingredients?">Yes, we do! You can purchase our high-quality extra virgin olive oil, cheese, bread, wine, and taralli directly from our website.</Ask>
        {/* prettier-ignore */}
        <Ask question="Can I purchase a gift card?">Yes! You can purchase gift cards in our restaurant starting at 10€. Gift cards are only redeemable when dining in.</Ask>
        {/* prettier-ignore */}
        <Ask question="Can I reserve the whole restaurant for an event?">Yes! Please drop us an email at events@panzero.nl specify the date and time, and we’ll get back to you to coordinate the event.</Ask>
        {/* prettier-ignore */}
        <Ask question="Do you accept credit cards and debit cards?">Yes, we accept all major credit cards (MasterCard, Visa, American Express, and Discover).</Ask>
        {/* prettier-ignore */}
        <Ask question="Can I bring my dog with me to Panzerò?">Yes! We are very dog friendly, and we will be able to accommodate you on the patio.</Ask>
        {/* prettier-ignore */}
        <Ask question="I think I left something of mine at Panzerò. Whom should I contact?">Please give us a call at 0102378426 and let us know what you forgot. You can also send us an email at administration@panzero.nl</Ask>
        {/* prettier-ignore */}
        <Ask question="I ordered through the Panzerò website but my delivery didn't arrive yet/or I received the wrong order. What should I do?">We manage orders through our website with our delivery team. So if you experience any issues please contact Panzerò directly by calling us at 0102378426 and we will be happy to assist you. For any order modifications please email us at delivery@panzero.nl</Ask>
        {/* prettier-ignore */}
        <Ask question="What if I’m not around when my rider arrives?">If for any reason you won’t be available to grab your order at the time of delivery please let us know by calling 0102378426. Please also check your emails during the delivery window for any potential updates from your rider. Your rider might also call you to check in during the delivery window using the phone number 06 38984610.</Ask>
        {/* prettier-ignore */}
        <Ask question="I ordered through UberEats but my delivery didn't arrive yet/or I received the wrong order. What should I do?">For any issue with your UberEats order please use the UberEats app to contact customer service.</Ask>
      </div>
      <PromoWebshop tight />
    </Layout>
  );
};

export default FAQ;

export const Head: HeadFC = () => (
  <SEO title="Customer's Frequently Asked Questions" />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
