import React from "react";
import { Trans, useTranslation } from "react-i18next";
const Timetable = ({ className = "" }) => {
  const { t } = useTranslation();
  return (
    <table className={`m-2 ${className}`}>
      <colgroup>
        <col className="bg-yellow-300/20" />
        <col className="w-24" />
      </colgroup>
      {[
        { weekday: "Monday", hours: "Closed" },
        { weekday: "Tuesday", hours: "12:00&mdash;21:30" },
        { weekday: "Wednesday", hours: "12:00&mdash;21:30" },
        { weekday: "Thursday", hours: "12:00&mdash;21:30" },
        { weekday: "Friday", hours: "11:00&mdash;22:00" },
        { weekday: "Saturday", hours: "11:00&mdash;22:00" },
        { weekday: "Sunday", hours: "11:00&mdash;21:00" },
      ].map(({ weekday, hours }, key) => (
        <tr key={key}>
          <td className="uppercase text-right px-2">
            <Trans>{weekday}</Trans>
          </td>
          <td
            className="text-lg"
            dangerouslySetInnerHTML={{ __html: t(hours) }}
          />
        </tr>
      ))}
    </table>
  );
};

export default Timetable;
